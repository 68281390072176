import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectFilterLookups (Vue) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    apiCalls.push({ name: 'selectTcontrato', method: 'tcontrato.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectContrato (Vue, filter, search, sorter, page, idcliente, idclientePotencial, idpresupuestocli) {
    const apiFilter = new APIFilter()
      .addGT('estado', 0)
    if (idclientePotencial) {
      apiFilter.addExact('idcliente_potencial', idclientePotencial)
    }
    if (idcliente){
      apiFilter.addExact('idcliente', idcliente)
    }
    if (idpresupuestocli){
      apiFilter.addExact('idpresupuestocli', idpresupuestocli)
    }
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.contrato.searchFilter(search))
    }
    const resp = await Vue.$api.call(
      'contrato.select',
      {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectContratoRows (Vue, pks) {
    const apiFilter = new APIFilter()
      .addIn('idcontrato', pks)
    const resp = await Vue.$api.call('contrato.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
